import { onAuthStateChanged, signInWithCustomToken, User } from 'firebase/auth';
import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { auth } from '../../services/firebase';
import { noOpFn } from '../../shared/utils';

type FetchWithAuthHeader = (
  input: RequestInfo,
  init?: RequestInit
) => Promise<Response>;

export interface AuthStore {
  user?: User;
  signIn(email: string, password: string): void;
  signOut(): void;
  fetchWithAuthHeader: FetchWithAuthHeader;
  hasRdToken: boolean;
}

const AuthContext = createContext<AuthStore>({
  signIn: noOpFn,
  signOut: noOpFn,
  fetchWithAuthHeader: fetch,
  hasRdToken: false,
});

const AuthProvider: FunctionComponent = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(
    auth.currentUser ? auth.currentUser : undefined
  );
  const [hasRdToken] = useState(false);

  const signOut = useCallback(async () => {
    auth.signOut();
    try {
      await fetch(`/api/users/logout`);
    } catch (e) {
      //
    }
  }, []);

  const signIn = useCallback(async (email: string, password: string) => {
    try {
      const res = await fetch(`/api/users/login`, {
        body: JSON.stringify({ email, password }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const { token } = await res.json();
      const userCred = await signInWithCustomToken(auth, token);
      setUser(userCred.user);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setUser(undefined);
    }
  }, []);

  const fetchWithAuthHeader = useCallback<FetchWithAuthHeader>(
    async (input, opts = {}) => {
      const { headers = {} } = opts;
      const token = user ? await user.getIdToken() : '';
      const optsWithHeader = {
        ...opts,
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      return fetch(input, optsWithHeader).then((res) => {
        if (res.status === 401 || res.status === 403) {
          signOut();
        }
        return res;
      });
    },
    [user, signOut]
  );

  useEffect(() => {
    onAuthStateChanged(auth, (changedUser) => {
      if (changedUser) {
        setUser(changedUser);
      } else {
        setUser(undefined);
      }
    });
  }, []);

  const value = useMemo(() => {
    return {
      user,
      signIn,
      fetchWithAuthHeader,
      hasRdToken,
      signOut,
    };
  }, [user, signIn, signOut, fetchWithAuthHeader, hasRdToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { useAuthContext, AuthProvider };
