import React, { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from './auth-context';

const RequireAuth: FunctionComponent = ({ children }) => {
  const { user } = useAuthContext();

  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ redirect: location.pathname }} />;
  }

  return (
    <>
      {children}
      {null}
    </>
  );
};

export { RequireAuth };
