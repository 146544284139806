const anyToError: (any: unknown) => Error = (any) => {
  if (any instanceof Error) {
    return any;
  }
  if (any && typeof any === 'object') {
    const toString =
      'toString' in any && typeof any.toString === 'function'
        ? any.toString
        : undefined;
    if (toString) {
      return new Error(toString());
    }
  }
  return new Error(`${any}`);
};

const noOpFn = () => undefined;

export { anyToError, noOpFn };
