import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginPageContainer } from '../modules/auth/login-page';
import { RequireAuth } from '../modules/auth/require-auth';
import { MainView } from '../modules/home/main-view';
import RdConfig from '../modules/rd-config/rd-config';

function RootRoute() {
  return (
    <Routes>
      <Route
        path="/config"
        element={
          <RequireAuth>
            <RdConfig />
          </RequireAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth>
            <MainView />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<LoginPageContainer />} />
    </Routes>
  );
}

export { RootRoute };
