import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import firebaseConfig from './firebase-config';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase();

export { auth, app, db };
