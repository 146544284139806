const firebaseConfig = {
  apiKey: 'AIzaSyBfum0g3tusaRZnfTphXtVppYEdm40gsD8',
  authDomain: 'rd-web-scraper.firebaseapp.com',
  projectId: 'rd-web-scraper',
  storageBucket: 'rd-web-scraper.appspot.com',
  messagingSenderId: '725058692164',
  appId: '1:725058692164:web:d3234dca924585a81c0811',
};

export default firebaseConfig;
