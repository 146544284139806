import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './modules/auth/auth-context';
import { RootRoute } from './routes';

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <AuthProvider>
          <RootRoute />
        </AuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
