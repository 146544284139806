import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import React, { FormEventHandler, FunctionComponent, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { anyToError } from '../../shared/utils';
import { useAuthContext } from './auth-context';

const LoginPage: FunctionComponent<{
  signIn(email: string, username: string): void;
}> = ({ signIn }) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>();
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string>('');

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setGeneralError('');
    setPasswordError('');
    setEmailError('');
    try {
      if (!password || !email) {
        setPasswordError(!password ? 'Invalid password' : '');
        setEmailError(!email ? 'Invalid email' : '');
      } else {
        signIn(email, password);
      }
    } catch (error) {
      setGeneralError(anyToError(error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Center padding="10">
      <form style={{ minWidth: 400 }} onSubmit={handleSubmit}>
        <FormControl>
          <Stack spacing="4">
            <div>
              <FormLabel htmlFor="email">Email Address</FormLabel>
              <Input
                isInvalid={!!emailError}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                isInvalid={!!passwordError}
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <Button
              type="submit"
              disabled={loading || !email || !password || !!generalError}
              isLoading={loading}
            >
              Sign In
            </Button>
          </Stack>
        </FormControl>
      </form>
    </Center>
  );
};

const LoginPageContainer = () => {
  const { user, signIn } = useAuthContext();
  return user ? <Navigate to="/" /> : <LoginPage signIn={signIn} />;
};

export { LoginPageContainer };
