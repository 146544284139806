import {
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  FormLabel,
  Heading,
  Input,
  Select,
  Spacer,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCopy,
  faExclamationTriangle,
  faMagnet,
  faSignOut,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import copy from 'clipboard-copy';
import { stringify } from 'qs';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useAuthContext } from '../auth/auth-context';
import {
  MagnetInfo,
  ScrapeMagnetsResponse,
  SITE_OPTIONS,
  UnrestrictTorrentResponse,
  SupportedSites,
} from '../../shared/shared-rd-types';
import { anyToError } from '../../shared/utils';
import './main-view.css';

enum Status {
  'idle' = 'idle',
  'loading' = 'loading',
  'success' = 'success',
  'error' = 'error',
}

const STATUS: { [key in Status]: IconProp } = {
  [Status.idle]: faMagnet,
  [Status.loading]: faSpinner,
  [Status.success]: faCheck,
  [Status.error]: faExclamationTriangle,
};

interface StreamInfo {
  name: string;
  link: string;
}

const MainView: FunctionComponent = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [magnetList, setMagnetList] = useState<MagnetInfo[]>();
  const [statusByMagnet, setStatusByMagnet] = useState(new Map());
  const [isScraping, setIsScraping] = useState(false);
  const [searchFragment, setSearchFragment] = useState('');
  const [currentSearchStr, setCurrentSearchStr] = useState('');
  const [selectedSite, setSelectedSite] = useState(SupportedSites.PIRATE_BAY);
  const [url, setUrl] = useState(SITE_OPTIONS[selectedSite].baseUrl);
  const [streamableLinksByMagnet, setStreamableLinksByMagnet] = useState<
    Map<string, StreamInfo[]>
  >(new Map());
  const [linksByMagnet, setLinksByMagnet] = useState<Map<string, string[]>>(
    new Map()
  );
  const { fetchWithAuthHeader, signOut } = useAuthContext();

  const isSubmitDisabled = searchFragment === '';

  const handleSetSelectedSite = (site: SupportedSites) => {
    setUrl(SITE_OPTIONS[site].baseUrl);
    setSelectedSite(site);
  };

  const getStatus = useCallback(
    (href: string): Status => {
      return statusByMagnet.get(href) || Status.idle;
    },
    [statusByMagnet]
  );

  const scrapeForMagnets = useCallback(async () => {
    if (!isSubmitDisabled) {
      setIsScraping(true);
      const siteOption = SITE_OPTIONS[selectedSite];
      const link = siteOption.urlConverter(siteOption.baseUrl, searchFragment);
      try {
        const fetchRes = await fetchWithAuthHeader(
          `/api/scrape-magnets?${stringify({
            url: link,
            siteKey: selectedSite,
          })}`
        );
        const res = (await fetchRes.json()) as ScrapeMagnetsResponse;
        if (res.success) {
          setMagnetList(res.magnetLinks);
        } else {
          setMagnetList([]);
        }
      } catch {
        // eslint-disable-next-line no-alert
        alert('could not scrape links');
        setMagnetList([]);
      }
      setIsScraping(false);
    }
  }, [searchFragment, selectedSite, isSubmitDisabled, fetchWithAuthHeader]);

  const postTorrents = async (magnet: string): Promise<void> => {
    try {
      const fetchRes = await fetchWithAuthHeader(
        `/api/real-debrid/unrestrict?${stringify({
          link: magnet,
        })}`
      );
      const res = (await fetchRes.json()) as UnrestrictTorrentResponse;
      if (res.success) {
        setLinksByMagnet((prev) => {
          const map = new Map(prev);
          map.set(magnet, res.downloadLinks);
          return map;
        });
        setStreamableLinksByMagnet((prev) => {
          const map = new Map(prev);
          map.set(magnet, res.streamLinks);
          return map;
        });
        setStatusByMagnet((prev) => {
          const map = new Map(prev);
          map.set(magnet, Status.success);
          return map;
        });
      } else {
        throw new Error('Could not unrestrict');
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(anyToError(e).message);
      setStatusByMagnet((prev) => {
        const map = new Map(prev);
        map.set(magnet, Status.error);
        return map;
      });
    }
  };

  const getOnClickMagnet = (href: string) => {
    return () => {
      const currentStatus = getStatus(href);
      if (currentStatus === Status.idle || currentStatus === Status.error) {
        setStatusByMagnet((prev) => {
          const map = new Map(prev);
          map.set(href, Status.loading);
          return map;
        });
        postTorrents(href);
      }
    };
  };

  const copyAllMagnetLinks = useCallback(async (text: string) => {
    try {
      await copy(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    } catch {
      // eslint-disable-next-line no-console
      console.info('could not copy');
    }
  }, []);

  const list = magnetList || [];
  const magnetMap = new Map(list.map((info) => [info.href, info]));
  const uniqueMagnetList = Array.from(magnetMap.values());

  const getPrimaryColor = (a = 1) => {
    return `rgba(34, 36, 112,${a})`;
  };

  const pluralize = (str: string, num: number, altPluralText = `${str}s`) => {
    if (num === 0 || num > 1) {
      return `${num} ${altPluralText}`;
    }
    return `${num} ${str}`;
  };

  const resultsHeader = !isScraping && magnetList && (
    <Heading as="h4" size="sm" marginBottom="3">
      {`${pluralize('result', uniqueMagnetList.length)} for `}
      <u>
        <a href={`${url}${currentSearchStr}`}>{currentSearchStr}</a>
      </u>
    </Heading>
  );

  return (
    <Flex flexDir="column" alignItems="center" width="100%">
      <Stack padding="10" width="100%" maxWidth="1080">
        <form
          style={{ width: '100%' }}
          onSubmit={(e) => {
            e.preventDefault();
            setCurrentSearchStr(searchFragment);
            scrapeForMagnets();
          }}
        >
          <VStack spacing={2} align="stretch">
            <Flex justifyContent="space-between" alignItems="center">
              <Heading as="h1" size="md">
                RD Magnet Link Scraper
              </Heading>
              <Button onClick={signOut} padding="0">
                <FontAwesomeIcon icon={faSignOut} />
              </Button>
            </Flex>
            <div
              style={{
                padding: '1.5rem 1.5rem 2.25rem 1.75rem',
                border: `1px solid ${getPrimaryColor()}`,
                borderRadius: '5px',
                margin: '1em 0',
              }}
            >
              <Stack spacing={2}>
                <FormLabel>Select a Site</FormLabel>
                <Select
                  value={selectedSite}
                  onChange={(e) => {
                    if (e.target.value) {
                      handleSetSelectedSite(e.target.value as SupportedSites);
                    }
                  }}
                >
                  {Object.entries(SITE_OPTIONS).map(([key, option]) => {
                    return (
                      <option key={key} value={key}>
                        {option.name}
                      </option>
                    );
                  })}
                </Select>
                <FormLabel>Query</FormLabel>
                <Input
                  autoFocus
                  className="width-100"
                  value={searchFragment}
                  onChange={(e) => {
                    setSearchFragment(e.target.value);
                  }}
                />
              </Stack>
            </div>
            <Button
              bg={getPrimaryColor()}
              disabled={isSubmitDisabled}
              style={{ textTransform: 'uppercase' }}
              colorScheme="teal"
              width="100%"
              type="submit"
            >
              find magnet links
            </Button>
          </VStack>
        </form>
        <div style={{ padding: '1rem 0' }} className="results">
          {resultsHeader}
          {!isScraping &&
            uniqueMagnetList.length > 0 &&
            uniqueMagnetList.map(({ title, href }) => {
              const links = linksByMagnet.get(href);
              const streamableLinks = streamableLinksByMagnet.get(href);
              return (
                <Box
                  marginY="3"
                  paddingX="3"
                  paddingY="1"
                  border="1px solid grey"
                  borderRadius="5px"
                  className="magnet-entry"
                  borderColor={
                    links ? `${getPrimaryColor()}` : `${getPrimaryColor(0.1)}`
                  }
                  key={href}
                >
                  <Flex
                    alignItems="center"
                    borderBottom={links ? `1px solid ${getPrimaryColor()}` : ''}
                    className="magnet-info"
                  >
                    <Box paddingY="1">{title}</Box>
                    <Spacer />
                    <Box>
                      <FontAwesomeIcon
                        icon={STATUS[getStatus(href)]}
                        onClick={getOnClickMagnet(href)}
                      />
                    </Box>
                  </Flex>
                  {links && (
                    <Box paddingY="3">
                      <Flex>
                        <Box fontWeight="medium">Download Links:</Box>
                        <Spacer />
                        <Box>
                          {isCopied ? (
                            'copied!'
                          ) : (
                            <FontAwesomeIcon
                              onClick={() =>
                                copyAllMagnetLinks(links.join('\n'))
                              }
                              icon={faCopy}
                            />
                          )}
                        </Box>
                      </Flex>
                      {links.map((link) => {
                        return (
                          <div className="link" key={link}>
                            {link}
                          </div>
                        );
                      })}
                      <Flex marginTop="3">
                        <Box fontWeight="medium">Streaming Links:</Box>
                        <Spacer />
                      </Flex>
                      {streamableLinks &&
                        streamableLinks.map((link) => {
                          return (
                            <a
                              className="link"
                              key={link.link}
                              href={link.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.name}
                            </a>
                          );
                        })}
                    </Box>
                  )}
                </Box>
              );
            })}
        </div>
        {isScraping && (
          <Center>
            <CircularProgress isIndeterminate />
          </Center>
        )}
      </Stack>
    </Flex>
  );
};

export { MainView };
